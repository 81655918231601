import request from "./http";

export const getAssistResultInfo = async (params, project_name) => await request({
    method: 'get',
    url: `/assist_result/${project_name}`,
    params: params
})

export const getAssistProfileInfo = async (params, project_name) => await request({
    method: 'get',
    url: `/assist_profile/${project_name}`,
    params: params
})

export const getAssistRankInfo = async (params, project_name) => await request({
    method: 'get',
    url: `/assist_rank/${project_name}`,
    params: params
})