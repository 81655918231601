import { render, staticRenderFns } from "./AssistProfile.vue?vue&type=template&id=13cf940c&scoped=true&"
import script from "./AssistProfile.vue?vue&type=script&lang=js&"
export * from "./AssistProfile.vue?vue&type=script&lang=js&"
import style0 from "./AssistProfile.vue?vue&type=style&index=0&id=13cf940c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13cf940c",
  null
  
)

export default component.exports