import axios from 'axios'

let url = location.origin
const request = axios.create({
  // baseURL: 'https://wechat.ceba.ceshiren.com/dev/backend',
  baseURL: url + '/backend',
  // baseURL: 'http://39.102.48.202:9999',
  // baseURL: 'https://v4.poster.ceba.ceshiren.com/backend',
})

export default request